var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/money.ts
var Money = class _Money {
  constructor(cents) {
    this.cents = cents;
  }
  static {
    __name(this, "Money");
  }
  static cents(cents) {
    return new _Money(cents);
  }
  static euros(euros) {
    return new _Money(Math.round(euros * 100));
  }
  get inEuros() {
    return this.cents / 100;
  }
  get inCents() {
    return this.cents;
  }
  toString() {
    const formattedEuros = this.inEuros.toFixed(2);
    return `${formattedEuros} \u20AC`;
  }
};
export {
  Money
};
